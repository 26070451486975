// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

// ============ CSS ============
import 'bootstrap';
import '../../css/sites/devrelate';

// ============ Images ============
const images = require.context('../../images', true)
const imagePath = (name) => images  (name, true)

// ============ Document ready ============
$(document).ready(function () {
  $('.hamburger-icon').on('click', function () {
    $('.mobile-nav').toggle();
  });

  $('.main-carousel').flickity({
    pageDots: false,
    contain: true,
    cellAlign: 'left',
    wrapAround: false
  });
});


